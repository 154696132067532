.home-multi-sec-1 .multicolumn-card__info {
    display: none;
}

.home-multi-sec-1 .multicolumn-card.content-container {
    line-height: 0;
}

.home-banner-sec-4,
.home-banner-sec-3 {
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: -10px;
}

.home-collection-multicolumn span.multicolumn_card_cta {
    font-size: 18px !important;
    letter-spacing: 1px !important;
    font-weight: 700 !important;
    display: block;
    text-align: center;
    transform: none !important;
    left: unset !important;
    padding-top: 12px;
    max-width: 100%;
    line-height: 25.7143px;
}

/* Footer */
.footer__content-bottom {
    display: none;
}

.footer__content-top.page-width {
    max-width: 130rem;
    padding: 0 12px;
}

/* Header */
.header__menu-item span {
    font-size: 1.6rem !important;
    letter-spacing: 2px;
    line-height: 22.8571px;
}

form.search input {
    padding: 0 !important;
    min-height: auto;
    height: auto;
    border-bottom: 1px solid #000;
}

button.search__button {
    min-height: unset;
    height: auto;
}

.search-container {
    border-bottom: none !important;
}

.header__icons {
    align-items: baseline;
}

.wishlist_head_btn {
    top: 0;
}

.header__icon,
.header__icon--cart .icon {
    height: 3.5rem !important;
    width: 3.5rem !important;
}

.cart-count-bubble {
    line-height: 0 !important;
}

.banner__content p.banner__heading.inline-richtext.h0 {
    font-size: 34px;
    line-height: 25px;
    font-family: Baskerville MT Std;
    font-style: italic;
    filter: drop-shadow(-10.392px 6px 125px rgba(0, 0, 0, 0.93));
    letter-spacing: 6px;
}

.banner__content h2.h1.mega-title--large {
    font-size: 70px !important;
}

.banner__content .banner__buttons {
    margin-top: 0 !important;
}

.banner__content .banner__buttons a.button.button--secondary,
.collage .banner__buttons a.button.button--primary {
    height: 46px !important;
    max-width: 230px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 25px;
    font-weight: 400;
    font-family: arimoregular;
    box-sizing: border-box;
    min-height: unset !important;
}

.collage h2.banner__heading.inline-richtext.h1 {
    font-size: 24px;
    letter-spacing: 6px;
    line-height: 25px;
}

.collage h2.h1.mega-title.mega-title--large {
    font-size: 36px !important;
    line-height: 70px;
    text-transform: lowercase;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
    font-weight: 400;
}

/* Feature Collection */
.feature-collection h2.title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 35px;
    margin-bottom: 0 !important;
}

.right.collection__view-all a.button {
    margin-top: 0;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 25px;
    color: #000;
    font-weight: 600;
    font-family: arimoregular;
    max-width: 163px;
    width: 100%;
    height: 47px;
    border: 2px solid #000000;
}

.feature-collection .collection__title {
    padding: 0 !important;
}

.collage__item {
    line-height: 0;
}

.collage .collage__item--imageCard:before {
    bottom: 0 !important;
    max-height: unset;
}

.feature-collection .slider--desktop {
    scroll-padding-left: 0;
}

h3.card__heading.h5 {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
    line-height: 20px;
}

.price__container>* {
    font-size: 16px;
    letter-spacing: 1px;
    /*line-height: 30px;*/
    color: #000;
    font-weight: 400;
    font-family: arimoregular;
}

.card-information .price.price--on-sale {
    margin-top: 0 !important;
}

.feature-collection ul {
    column-gap: 30px;
}

.newsletter-form__field-wrapper .field:after {
    display: none;
}

label.cst_field__label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    pointer-events: none;
    transition: top 0.2s ease-in-out;
    right: 0;
    color: #333;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    margin: auto;
    text-align: center;
}

.search_form_for_desktop_only {
    display: none !important;
}

ul#predictive-search-results-products-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

li.predictive-search__list-item {
    width: 50%;
}

input#Search-In-Modal:focus {
    box-shadow: none;
}

.mobile_search_box {
    display: flex;
    flex-direction: row;
}

.mobile_search_icon_left {
    text-align: center;
    display: block;
    width: 44px;
    margin: auto;
}

button.mobile_open_search__button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    margin: auto;
    display: block;
}

details[open] .modal-overlay::after {
    background-color: #ffffff !important;
}

.field__input,
.customer .field input {
    border-bottom: 2px solid black;
}

/* CSS to hide the snippet by default */
.mobile-only {
    display: none;
}

/* .field input:focus ~ label {
    top: calc(var(--inputs-border-width) + -1.2rem);
  }
  .field input:focus ~ label,
  .field input:-webkit-autofill ~ label {
    top: calc(var(--inputs-border-width) + -1.2rem);
  } */
@media only screen and (min-width: 992px) {
    .predictive-search.predictive-search--header {
        max-width: 1200px !important;
        width: 70em !important;
        border-top: 2px solid black;
        margin-top: 3%;
    }
}

@media screen and (min-width: 990px) {
    .feature-collection .grid--4-col-desktop .grid__item {
        width: calc(25% - 30px * 3 / 4);
        max-width: calc(25% - 30px * 3 / 4);
    }
}

/* price and review star  */
@media screen and (max-width: 1410px) and (min-width: 990px) {
    .product__info-container.product__column-sticky .no-js-hidden.desktop_price {
        flex-wrap: wrap !important;
    }
    .product__info-container.product__column-sticky .no-js-hidden.desktop_price .price.price--large.price--show-badge {
        flex-basis: 66% !important;
    }
    .product__info-container.product__column-sticky .no-js-hidden.desktop_price .yotpo.bottomLine{
        flex-basis: 77%;
    }
    .swym-button.swym-add-to-wishlist-view-product {
        flex-basis: 8%;
    }
    .product__info-container.product__column-sticky .no-js-hidden.desktop_price .yotpo.bottomLine {
        margin-left: 0px !important;
    }
    .product .price .badge, .product .price__container {
     margin-bottom: 0rem !important;
    }
}

@media (max-width: 990px) {
    li.predictive-search__list-item {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .header__heading-logo-wrapper img {
        max-width: 56%;
        margin-left: 36%;
    }

    .search__button .icon {
        display: none;
    }
}

@media (min-width: 768px) {
    .collage .collage__item {
        width: 67.3%;
    }

    .collage .collage__item:nth-child(2) {
        width: 33.3%;
    }

    .feature-collection button.slider-button--prev {
        margin-left: -44px;
    }

    .feature-collection button.slider-button--next {
        margin-right: -44px;
    }

    .footer-block--menu.company {
        max-width: calc(37% - var(--grid-desktop-horizontal-spacing) * 3 / 4) !important;
        padding-right: 20px;
    }

    .footer-block.grid__item.customer-service {
        margin-right: 60px;
    }

    .footer-block--menu li {
        line-height: 1;
        margin-bottom: 2px;
    }

    .footer-block--menu.company ul.footer-block__details-content {
        column-count: 2;
        gap: 20px;
    }
}

/* CSS to show the snippet only on mobile devices */
@media (max-width: 767px) {
    .announcement-bar-slider .slider {
        width: calc(100% - 42px) !important;
        margin: auto;
    }

    .mobile-only {
        display: block;
    }

    button.reset__button.field__button {
        display: none;
    }

    body.overflow-hidden .utility-bar {
        display: none;
    }

    .search-modal.modal__content.gradient {
        padding: 50px 20px 0 !important;
    }

    .predictive-search.predictive-search--header {
        width: 26em !important;
        border-top: 2px solid black;
        margin-top: 8%;
    }

    .announcement-bar .slider-button--next,
    .announcement-bar .slider-button--prev {
        min-width: 20px !important;
        width: 20px;
        height: 20px;
        padding: 0;
    }

    .header {
        padding: 6px 10px !important;
    }

    header-drawer {
        margin-left: -5px !important;
    }

    .banner__content p.banner__heading.inline-richtext.h0 {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 3px;
        filter: unset;
    }

    .banner__content h2.h1.mega-title--large {
        font-size: 32px !important;
        margin: 2px 0 0;
        line-height: 40px;
        letter-spacing: 2px;
        font-weight: 500;
    }

    .banner__content .banner__buttons {
        position: absolute;
        bottom: 10%;
    }

    .banner__content .banner__buttons a.button.button--secondary,
    .collage .banner__buttons a.button.button--primary {
        height: 35px !important;
        max-width: 150px;
    }

    .home-banner-sec-4,
    .home-banner-sec-3 {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    .home-collection-multicolumn span.multicolumn_card_cta {
        font-size: 16px !important;
    }

    /* .home-collection-multicolumn ul {
      margin-bottom: 0 !important;
    } */
    .home-collection-multicolumn .section-template--17210061422765__multicolumn_5-padding {
        padding-bottom: 10px;
        padding-top: 30px;
    }

    /* .collage .banner__media {
      display: block;
    } */
    .feature-collection ul {
        column-gap: 20px;
    }

    .collage h2.banner__heading.inline-richtext.h1 {
        font-size: 24px;
        letter-spacing: 3px;
        line-height: 26px;
    }

    .collage h2.h1.mega-title.mega-title--large {
        font-size: 36px !important;
        line-height: 46px;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
    }

    .collage .banner__media {
        height: auto !important;
    }

    .collage .banner__content {
        height: 100% !important;
    }

    .home-multi-sec-1 ul {
        margin-bottom: 0 !important;
    }

    .feature-collection h2.title {
        font-size: 24px;
        letter-spacing: 2px;
        line-height: 44px;
    }

    .feature-collection slider-component {
        padding: 0 30px;
        padding-bottom: 40px;
    }

    .feature-collection .collection .slider--tablet.product-grid {
        scroll-padding-left: 0 !important;
    }

    .feature-collection .collection .slider--tablet.product-grid {
        scroll-padding-left: 0 !important;
        margin-bottom: 0;
    }

    h3.card__heading.h5 {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 18px;
    }

    .large-up-hide.mobbtn {
        text-align: center;
    }

    .right.collection__view-all a.button {
        font-size: 12px;
    }
}

.digital-gift-card-page-hide-me {
    display: none !important;
}

/* wishlist styling start */
/* .page-width.page-width--narrow.section-template--15558127648834__main-padding {
    max-width: 1400px;
    padding: 0;
}

.page-width.page-width--narrow.section-template--15199641567298__main-padding {
    max-width: 1400px;
    padding: 0;
}

.page-width.page-width--narrow.section-template--15558127648834__main-padding h1 {
    display: none;
}

.page-width.page-width--narrow.section-template--15199641567298__main-padding h1 {
    display: none;
} */

.swym-wishlist h1.main-page-title.page-title.h0.scroll-trigger.animate--fade-in {
    display: none;
}

.search-results h1.main-page-title.page-title.h0.scroll-trigger.animate--fade-in {
    display: none;
}
.ssr-search-results h1.main-page-title.page-title.h0.scroll-trigger.animate--fade-in {
    display: none;
}

.swym-wishlist .page-width.page-width--narrow {
    max-width: 1400px;
    padding: 0;
}
.swym-ui-component.swym-wishlist-page .swym-wishlist-container-title-bar .swym-wishlist-main-title {
    font-weight: inherit;
    font-size: inherit;
    letter-spacing: inherit;
}

.swym-ui-component.swym-wishlist-page {
    max-width: none !important;
}

.swym-ui-component .swym-wishlist-grid {
    max-width: none !important;
    padding-left: 0px !important;
}

button#swym-wishlist-context-menu {
    cursor: pointer;
    position: relative;
    margin-left: 15px;
    background: 0;
    padding: 0;
    width: 38px;
    height: 38px;
    transition: all ease-in-out 0.1s;
    border: none;
    margin: 10px 0;
    color: #000;
}

.custom_wishlist .p_image {
    position: relative;
    padding-bottom: 100%;
}

.swym-ui-component .swym-wishlist-grid .swym-wishlist-image-wrapper {
    height: auto !important;
}

.swym-ui-component .swym-wishlist-grid .swym-wishlist-item .swym-add-to-cart-btn {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 6px;
    display: block;
    background: black;
    width: 100%;
    margin: 0;
}

button.swym-button:hover {
    opacity: 1 !important;
    background: #424242 !important;
    opacity: .6 !important;
}

.swym-ui-component .swym-wishlist-grid .swym-wishlist-item .swym-title {
    white-space: unset !important;
    overflow: visible;
    font-family: 'arimoregular', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    width: 92%;
    max-height: 80px;
    height: 40px;
    color: #000 !important;
}

.swym-variant-title.swym-text.swym-title-2 {
    max-height: 80px;
    height: 38px;
    overflow: hidden !important;
    white-space: unset !important;
    color: #000 !important;
}

.swym-ui-component .swym-simple-wishlist-container .swym-simple-wishlist-container-content .swym-wishlist-detail {
    overflow: hidden !important;
}

.swym-ui-component .swym-wishlist-grid li {
    list-style: none;
    background: #fff;
    max-width: 23%;
    width: 100% !important;
    margin: 0 1% 2% 1% !important;
    padding: 0;
    display: flex;
    position: relative;
}

.swym-ui-component.swym-wishlist-page .swym-wishlist-container-title-bar .swym-wishlist-main-title {
    font-weight: 200 !important;
    font-size: 14px !important;
    letter-spacing: 0em !important;
    color: #000 !important;
    display: block;
}

.swym-product-final-price.swym-value {
    font-family: 'arimoregular', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #000;
}

button.swym-delete-btn.swym-nav.swym-nav-1.swym-is-button {
    justify-content: center;
    background: #fff;
    z-index: 1;
    cursor: pointer;
}

.swym-ui-component .swym-share-btn {
    display: none;
}

.swym-ui-component .swym-wishlist-grid .swym-wishlist-item .swym-product-price {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1rem;
}

.swym-ui-component .swym-product-price.swym-has-sale .swym-product-final-price {
    color: #b20000 !important;
}

.swym-ui-component .swym-product-price .swym-product-original-price {
    color: #999 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: 'arimoregular', sans-serif !important;
}

@media only screen and (max-width: 900px) {
    .swym-ui-component .swym-wishlist-grid li {
        list-style: none;
        background: #fff;
        max-width: 100%;
        width: 48% !important;
        margin: 0 1% 4% 1% !important;
    }


    .swym-ui-component .swym-wishlist-grid .swym-wishlist-image-wrapper {
        width: 100% !important;
        object-fit: cover;
        max-height: fit-content !important;
    }

    .swym-ui-component .swym-wishlist-grid .swym-wishlist-item .swym-title {
        width: auto;
        height: 58px;
    }

    .swym-ui-component .swym-simple-wishlist-container .swym-wishlist-detail .swym-wishlist-main-title {
        font-weight: 200 !important;
        font-size: 14px !important;
        letter-spacing: 0em !important;
        color: #000 !important;
    }

    .swym-ui-component .swym-wishlist-context-menu .swym-wishlist-context-menu-content {
        bottom: 77em;
        width: 77%;

    }
}


/* CSS Changes for Help Pages Starts - vikrant */

.size-fit table td {
    line-height: 30px;
    font-size: 12px;
    border-width: 0;
    border-right: 1px solid #000;
    text-align: center;
    color: #000;
}

.size-fit {
    margin-bottom: 20px;
}

div.collapse.show div.card-block tbody {
    background-color: white;
}

div.size-fit table#scope tbody {
    background-color: #d7d7d7;
}

.tab-content .tab-pane .content .card .card-block {
    margin: 20px 0px !important;
}

.terms-content div.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
    display: block;
}

.terms-content .tab-content .tab-pane .content p {
    text-align: justify;
}

@media only screen and (max-width: 767px) {

    div.tab-content div.tab-pane div.content div.termsOfSale p {
        text-align: justify !important;
    }

    div.collapse.show div.card-block.text-center.justify-center {
        overflow-x: auto !important;
    }

}

/* Store Locator page Section Css starts */

.store-locator-width.page-width {
    max-width: 1200px !important;
    margin: 0 auto !important;
}

.store-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
}

div.store-locator-width.page-width div.section-header.text-left {
    margin-bottom: 55px;
    padding-top: 55px;
    text-transform: uppercase;
}

div.store-locator-width.page-width div.section-header.text-left h2 {
    font-size: 30px;
    letter-spacing: 0.1rem;
    font-family: arimoregular !important;
}

.store-locator-width.page-width {
    padding-left: 55px;
    padding-right: 55px;
}
@media only screen and (max-width: 767px) {

    .store-locator-width.page-width {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    .store-grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 30px;
    }

    div.store-locator-width.page-width div.section-header.text-left h2 {
        margin: 0;
    }

    div.store-locator-width.page-width div.section-header.text-left {
        margin-bottom: 35px;
        padding-top: 35px;
    }
    .store-grid__item.text-left:last-child {
        margin-bottom: 60px;
    }

}

.store-grid__item {
    position: relative;
}

.feature-row__image-wrapper {
    margin: 0 auto 19.44444px;
    position: relative;
    width: 100%;
}

.store-grid .feature-row__image-wrapper .feature-row__image {
    width: 100%;
    position: absolute;
    top: 0;
    object-fit: cover;
    height: 100%;
}

.store-grid__item details summary {
    position: relative;
    cursor: pointer;
}

.store-grid details>summary {
    list-style-type: none;
}

.store-grid__item details[open] summary:after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.store-grid__item details summary:after {
    content: "";
    background-image: url(/cdn/shop/files/right.png?v=1615532227);
    width: 15px;
    height: 15px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 5px;
    right: 5px;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}


.store-grid__item .h4 {
    font-size: 30px;
    line-height: 25px;
    color: #000;
    font-family: Baskerville !important;
    text-transform: lowercase;
    left: 0;
    font-style: italic;
}

@media only screen and (max-width: 767px) {
    .store-grid__item .h4 {
        margin-bottom: 0;
    }
    .store-grid__item p {
        margin-top: 5px;
    }
}

.store-grid__item .rte-setting {
    margin-top: 30px;
}

.store-grid__item .rte-setting>* {
    color: #000;
}
.template-term-of-use .pagecontent h3 {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 5px;
}
.onetrust-pc-dark-filter {
    display: block !important;
}

/* SMTECH-352 Fix CSS STARTS*/
section.shopify-section.section div.page-width.page-width--narrow {
    max-width: 100%;
}
.do_not_sell_page {
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
    display: block;
}

div.do_not_sell_page .pagecontent p {
    color: #000;
    font-weight: 400;
    line-height: 1.42857143;
}
.do_not_sell_page h1 {
    text-transform: uppercase;
    font-size: 36px;
}
.do_not_sell_page h2 {
    font-size: 16px;
    letter-spacing: 0;
    text-transform: inherit;
    margin-top: 55px;
    margin-bottom: 27.5px;
}

.do_not_sell_page ul li {
    font-size: 14px;
 color:#000;
}
.do_not_sell_page .rte a, .do_not_sell_page .rte a span {
    color: #00f;
}

.term-of-use .pagecontent h3 {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 5px;
}
.right-content.pagecontent img {
    width: auto !important;
    padding-right: 20px;
    height: auto;
    max-width: 100%;
}

.privacy-policy h3 {
    display: inline-block;
    margin: 0;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #000;
}

.new-image-with-text-section .new-image-with-text-content-wrapper h2 {
    letter-spacing: .1em;
}
.new-image-with-text-section .new-image-with-text-content-wrapper h2.subheading {
    letter-spacing: .1em;
}
.careers .new-image-with-text-section .new-image-with-text-content-wrapper p {
    letter-spacing: 0;
    line-height: 1.42857143;
}
.careers .new-image-with-text-btn .dc-btn {
    font-size: 14px;
    letter-spacing: 0;
}
.careers .dc-btn:hover {
    background: #000;
    color: #fff !important;
}
.careers a {
    color: #000;
}
.student-discount-banner .student-container h1 {
color:#fff;
}
.sustainability .sustainability-top-img img{
    padding-right: 0px !important;
}
.sustainability .sustainability-section .sustainability-top-content p {
font-size: 15px !important;
}

.sustainability .right-content.pagecontent img {
    height: 100% !important;
    padding-right: 0px !important;
    width: 100% !important;
}

/* re-vita page css starts */
.recurate-product-list, #orders-div, #recurate-submission-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.recurate-product-list h5, #orders-div h4 {
    flex-basis: 100%;
}

.recurate-filter-div.recurate-row.mb-30 {
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    min-height: 0px !important;
}

hr.recurate-line-1 {
    width: 100%;
}
.recurate-filter-div #select-filters {
    max-width: 55.3333% !important;
}
#orders-div .recurate-product-info-div>.recurate-h5, #orders-div .recurate-product-info-div>.recurate-body, #recurate-submission-list .recurate-product-info-div>.recurate-h5 {
    margin-bottom: 10px !important;
}
.recurate-product-list .recurate-product-info-div h5, #orders-div .recurate-product-info-div h5 {
    text-align: center !important;
}
.recurate-container .recurate-h5, .recurate-card-secondary .recurate-h5 {
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 21px;
    letter-spacing: 0.04px;
    font-size: 1.6rem;
}
#orders-div .card form, #orders-div .card .line-items-container {
    height: 100%;
}


#orders-div .recurate-product-info-div, #recurate-submission-list .recurate-product-info-div {
    max-width: 80% !important;
}
.edit-profile a.cancel_profile.btn {
    color: #fff !important;
}

/* re-vita page css ends */

@media only screen and (max-width: 767px) {
    .do_not_sell_page {
        padding: 0 10px;
    }
    .right-content.ordertracking {
        padding: 5px 10px !important;
    }

    #ordertrackingform input {
        padding: 8px 15px !Important;
    }
    #ordertrackingform div {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    #ordertrackingform .order-page-field {
        width: calc(50% - 0.5rem);
    }
    #ordertrackingform input {
        width: 100%;
    }
    .collapse.show {
        padding: 0 0rem !important;
    }
    .order_submit_seperate .order-page-field:first-child {
        flex: 0 0 100%;
    }
    input#billzip {
        width: calc(50% - 0.5rem) !important;
    }

    .privacy-policy .pagecontent ol li, .pagecontent ul li {
        margin-bottom: 0px !important;
        margin-left: 0px !important;
    }
    .privacy-policy table {
        table-layout: auto !important;
    }
    .privacy-policy th{
        padding: 5px 4px !important;
    }
    .faqs table{
        table-layout: auto !important;
    }
    .recurate-filter-div {
        flex-direction: column-reverse !important;
        margin: 0px;
    }
    .recurate-filter-div #select-filters {
        max-width: 100% !important;
    }
    .recurate-filter-div #select-filters .recurate-order-filter {
        flex: 0 1 0%;
    }
    .recurate-filter-div #select-filters {
        width: 93% !important;
        left: 7px;
    }
    .section.shopify-section {
        padding: 0px 0px 10px 0px;
      }
    
      .multicolumn-card__info .link {
        padding: 12px 15px 0px;
    }
}

.product__description.rte.quick-add-hidden h2.detail-Btnn.clickable button {
    background: none;
    border: none;
    padding: 0px; /* Space for the ::after content */
    outline: none; /* Customize focus outline as needed */
    font-size: 16px;
    font-weight: 100;
    letter-spacing: normal;
    font-family: 'acumin-pro-extra-condensed', sans-serif;
    width: 100%;
    text-align: left;
}

.product__description.rte.quick-add-hidden h2.detail-Btnn.clickable button:focus {
    outline: 2px solid #000; /* Example focus style */
}

.detail-container.hidden {
    display: none;
}

.detail-container {
    display: block;
}

.toggle-button:focus-visible {
    opacity: 1;
  }

.list-menu.list-menu--inline button.toggle-button {
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0;
    padding: 15px 0;
    margin-left: -1em;
    margin-right: -15px;
    background: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    background: unset;
    text-decoration: none;
}

.list-menu.list-menu--inline button.toggle-button:focus{
    opacity: 1;
}

.list-menu.list-menu--inline .toggle-button svg {
    width: 20px;
    height: 20px;
}


.cart-item__link {
    position: unset !important;
    height: 94% !important;
}

@media only screen and (min-width: 991px) {
    body *:not(svg):not(#onetrust-accept-btn-handler):not(.swym-add-to-wishlist_custom):not(.star-clickable.yotpo-bottomline):not(a.text-m):not(a.product-img):not(.swatch-element.click_trigger_swatch):not(.swatch-element):not(.customColor):not(.filters-dropdown):not(.yotpo-dropdown-button):not(.review-star):not(.shopify-section):not(.mainSections):not(.social-icons a):not(.isp_related_products .slick-arrow):not(.topbar-rolling-text *):focus, header a:focus, #search_input:focus, header #mainNavDesk .hasDropDown ul li>a:focus, header #mainNavDesk .hasDropDown span.posrel>a:focus, .visuallyhidden.focusable.skip-link:focus, header #mainNavDesk .col_image_only img:focus, header #mainNavDesk ul li span:focus, header #mainNavDesk ul.dropdownmenu li:focus, header #mainNavDesk ul.dropdownmenu li a:focus, header #mainNavDesk ul.dropdownmenu li ul li a:focus, .close-modal:focus, .tab-content :focus, .apply_squad .cm_arrow_btn:focus, .collaborate-us .collab_wrapper a:focus, a.closecart:focus, .cartDrawer .quick-cart-item .quick-cart-details .grid-form-add .adjust:focus, .cartDrawer .quick-cart-item .quick-cart-details .grid-form-add .adjust:focus-visible, .cartDrawer .quick-cart-item .quick-cart-details a.remove_item:focus, .ais-facets .ais-facet--item>div input[type=checkbox]:focus+label, .swym-add-to-wishlist_custom:focus svg {
        border: 2px solid #494113 !important;
        border-radius: 5px;
        box-shadow: none !important;
        outline: 2px solid #553D0C !important;
    }
}

button#addToCartBtn:hover, button#sticky-add-to-cart-btn:hover {
    background: #2c2929 !important;
}

/* .button:hover:not(.slick-arrow), .button:focus:not(.slick-arrow), button:not(.slick-arrow):hover:not(.slick-arrow), button:not(.slick-arrow):focus:not(.slick-arrow), input[type=submit]:hover:not(.slick-arrow), input[type=submit]:focus:not(.slick-arrow), input[type=button]:hover:not(.slick-arrow), input[type=button]:focus:not(.slick-arrow) {
    background: #424242;
    color: #fff;
} */
@media only screen and (min-width: 991px) {
    body .swym-add-to-wishlist_custom.swym-added:focus svg, footer .social-icons a:focus, body .topbar-rolling-text *:focus {
        border: 1px solid #fff !important;
    }
}




/* SMTECH-516 CSS */

@media (min-width: 767px) {
    .colorTag .swatch-wrapper {
        border: 0;
        padding: 10px 0 0 0;
    }
}
.sr-color-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* Added to prevent text from breaking into a new line */
    border: 0;
}

fieldset.swatch.clearfix.select_size.new_custom_swatch_size {
    padding: 0;
    border: 0;
}
.cart-item__name.h4.break h4 {
    margin: 0;
    max-width: 30rem;
    font-size: 1.2rem;
    font-family: arimoregular;
    font-weight: 600;
}


.close-btn-wrapper {
    width: 20px !important;
    height: 20px !important;
    top: 2% !important;
    left: 97% !important;
    position: absolute !important;
}

@media screen and (max-width: 480px) {
    .colorTag {
        max-width: 100%; /* Ensures the parent container doesn't exceed the screen width */
    }
    .colorTag fieldset.swatch-wrapper {
        display: flex;
        flex-wrap: nowrap !important;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        border: 0px;
        padding: 10px 0 0 0;
        max-width: 100%; /* Ensure the fieldset doesn't exceed the screen width */
        box-sizing: border-box; /* Include padding and border in the element's width */
    }
    
    .colorTag fieldset.swatch-wrapper > * {
        flex-shrink: 0; /* Prevent shrinking of child elements */
        scroll-snap-align: start; /* Ensures each element snaps to the start when scrolling */
    }
    
    .colorTag fieldset.swatch-wrapper::-webkit-scrollbar {
        display: none; /* Hides the scrollbar */
    }
    .colorTag .swatch-element {
        flex: 0 0 calc(100% / 14) !important;
        height: auto !important;
    }
    .modal-backdrop.yotpo-media-modal .yotpo-media-modal-media {
        overflow: unset !important
    }
    .close-btn-wrapper {
        top: 11px !important;
        left: 22em !important;
    }
    .yotpo-device-mobile .yotpo-media-modal .yotpo-modal-right-arrow, .yotpo-device-tablet .yotpo-media-modal .yotpo-modal-right-arrow {
        background-color: transparent !important;
        left: 86% !important;
        top: 30% !important;

    }
    .yotpo-device-mobile .yotpo-media-modal .yotpo-modal-left-arrow, .yotpo-device-tablet .yotpo-media-modal .yotpo-modal-left-arrow {
        background-color: transparent !important;
        left: 5% !important;
        top: 30% !important;

    }

}


/* Skip to content */


/* skip-link css */
.skip-link {
    background-color: #fff;
    padding: 1em;
    z-index: 10000;
    display: block;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.visuallyhidden:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: normal;
    background-color: #fff;
    padding: 0.5em !important;
    z-index: 10000;
    display: block;
}
.klarna_poup svg {
    max-height: 12px !important;
}

svg.yotpo-close-icon.btn-close {
    background: transparent !important;
    color: #000 !important;
    position: relative !important;
}
.yotpo-close-icon {
    width: 14px !important; /* Fixed icon size */
    height: 14px !important;
}

/* men nav menu styling */
nav.header__inline-menu.men-menu>ul>li>a>span {
    font-size: 14px !important;
}

input#Search-In-Modal {
    flex-shrink: 1;
    width: 17.5rem;
}

@media screen and (min-width: 481px) and (max-width: 768px) { 
    .close-btn-wrapper {
        left: 95% !important;
    }
}

/* cart page styling starts */
.betsey_cart_page {
max-width: 1500px;
    margin: 0 auto;
    width: 95%;
}

.betsey_cart_page .cart-page-title {
    font-size: 50px;
    line-height: 55px;
    color: #ef2f95;
    text-transform: uppercase;
    margin-bottom: 30px;
}
.betsey_cart_page .title-wrapper-with-link {
    flex-direction: column-reverse;
    align-content: flex-start;
    align-items: flex-start !important;
}
.betsey_cart_page .continue-shoping-link {
    font-size: 16px;
    line-height: 18px;
    color: #000;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 25px;
    display: inline-block;
}

.betsey_cart_page table.cart-items .cart__row.cart__header th {
    padding: 15px 0;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    border-bottom: 2px solid #000000;
    opacity: 1 !important;
}

.betsey_cart_page .cart-items thead th:nth-child(2) {
    width: 25%
  }
  .betsey_cart_page .cart-items thead th:nth-child(4) {
    width: 15%;
  }
  .betsey_cart_page .cart-items thead th:nth-child(5) {
    text-align: center !important ;
  }
  .betsey_cart_page .cart-item__price-original-wrapper {
    text-align: left ;
  }
  .betsey_cart_page .cart-item>td+td {
    padding-left: 0 !important;
}

.betsey_cart_page a.cart-item__name.h4.break.sm-cart--title {
    color: #000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
}
.betsey_cart_page .cart-item__details .cart__meta-text {
    font-size: 16px;
    line-height: 22px;
    color: #021641;
    font-style: normal;
    text-transform: uppercase;
}
.betsey_cart_page .cart-item__price-original-wrapper {
    padding: 20px 0;
    vertical-align: top;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
}
.betsey_cart_page .cart-item__price-wrapper span {
    padding: 20px 0;
    vertical-align: top;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
    text-align: start;
}
.betsey_cart_page .cart-item {
    border-bottom: 1px solid #e8e9eb;
}
.betsey_cart_page .cart-items td {
    border-bottom: 2px solid black !important;
}
.betsey_cart_page .cart-item__quantity-wrapper {
    flex-direction: column;
    align-items: center;
}
.betsey_cart_page .cart-item cart-remove-button {
    max-width: 50% !important;
}
.betsey_cart_page img.cart-item__image {
    max-width: 74px;
}

@media screen and (max-width: 790px) {
    .betsey_cart_page .cart-page-title {
        font-size: 40px;
        line-height: 45px;
    }
}

@media screen and (max-width: 749px) {
    .betsey_cart_page .cart-items thead tr {
        border-bottom: 2px solid black !important;
    }
    .betsey_cart_page table.cart-items .cart__row.cart__header th {
        border-bottom: 0px solid #000000 !important;
    }
    .betsey_cart_page .cart-items td {
        border-bottom: 0px solid black !important;
    }
    .betsey_cart_page .cart-item {
        border-bottom: 2px solid #000000 !important;
    }
    .betsey_cart_page table.cart-items .cart__row.cart__header th {
        display: none;
    }
}


/* ADA issue regarding the PROMO code or Cart Drawer */
.floating-label {
    position: relative;
  }

  .floating-label label {
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 5px; /* Default position */
    font-size: 16px;
    opacity: 0.6;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }

  .floating-label input {
    padding: 10px 10px 10px 30px; /* Padding to make space for the label */
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }

  .floating-label.is-floating label,
  .cart-sidebar-discount input:focus + label {
    top: -4px; /* Moves the label above the input */
    font-size: 12px; /* Smaller font size for floating */
    font-weight: bold;
    opacity: 1;
    color: #000;
}

/* Trends page css */
.trends .rich-text__wrapper.rich-text__wrapper--center.page-width {
    padding: 0px 55px 15px;
    max-width: 1300px;
}
#shopify-section-template--16435461881921__rich_text_TYMcx6 .rich-text {
    padding: 0px 30px 15px 30px !important;
}
.r-pdp .r-pdp-title-messaging p {
    letter-spacing: normal;
}
.r-pricing-single .money {
    color: #000;
}
.r-pdp-option-color span{
    color:#000;
}
.r-pdp-option-size span {
    color: #000;
}
.r-pdp-option-condition span {
    color: #000;
}
.r-app-wrap .r-text-danger {
    text-align: left;
}
.r-app-wrap .r-pdp-accordion .r-pdp-accordion-body p {
    letter-spacing: normal;
}
.r-pdp-description ul li {
    list-style: none;
    color: #000;
}
.r-page-powered-by.r-text-center .r-dtxt {
    color:#000;
}
div#r-ab-powered-by-recurate_resale_made_easy_recurate_powered_by_PPLKbG {
    margin-top: 9.5rem;
}


/* My Account design css */
button#birthday {
    padding: .84375em .875em .78125em;
    text-transform: uppercase;
    border: 0;
    border-radius: 2px;
    font-family: Gotham;
    font-weight: 500;
    background: #000;
}

/* SMTECH-801 Order Stying css start */

.edit_profile .one-half {
    margin-bottom: 30px;
    padding-right: 20px;
}
.one-half {
    width: 50%;
}
.edit_profile .one-half h3 {
    padding-top: 20px;
    border-top: 5px solid #000;
}
.edit_profile h3 {
    font-size: 25px;
    font-family: Gotham;
    font-weight: 700;
    margin-bottom: 30px;
}
.edit_profile input[type=password], .edit_profile input[type=email] {
    padding: 7px 4px !important;
    font-family: 'Gotham Book';
}
/* For WebKit browsers */
::-webkit-input-placeholder {
    font-style: normal !important;
    text-transform: none !important; /* Ensure this is applied */
    color: #bcb4b4 !important;
}

/* For Mozilla Firefox */
:-moz-placeholder {
    font-style: normal !important;  
    text-transform: none !important; /* Ensure this is applied */
    color: #bcb4b4 !important;
}

/* For older versions of Firefox */
::-moz-placeholder {
    font-style: normal !important;  
    text-transform: none !important; /* Ensure this is applied */
    color: #bcb4b4 !important;
}

/* For Internet Explorer */
:-ms-input-placeholder {  
    font-style: normal !important; 
    text-transform: none !important; /* Ensure this is applied */
    color: #bcb4b4 !important;
}

.edit_profile .cancle_profile {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    color: #000;
    text-transform: uppercase;
    text-decoration: underline !important;
    margin-left: 10px;
    line-height: 33px;
}


/* Order Details Page css */
 .my_account_main_div{
    max-width: 1500px;
    margin: 0 auto;
    width: 95%;
 }
a.return_order_link {
    font-family: Gotham Book !important;
    font-weight: 400;
    color: #3d4246 !important;
    text-decoration: underline;
    margin-bottom: 10px;
}
.order_detail_desktop .order-box {
    margin-bottom: 30px;
    position: relative;
    padding: 15px 0;
}
.order_detail_desktop .order-box p {
    font-size: 15px;
    line-height: 20px;
    color: #535353;
    margin: 0;
}
.order_detail_desktop .order-box:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 5px;
    background: #000;
}
.order_detail_desktop .order-box h4 {
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    margin-bottom: 5px;
    letter-spacing: 0;
}
.order_detail_desktop .flex .order-box {
    width: 30%;
}

.order_detail_desktop .flex .order-box:before {
    width: 100%;
}

.order_detail_desktop .order-box table td {
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    color: #535353;
}

.order-box table {
    border-collapse: separate !important;
    border-spacing: 0;
    margin: 0 0 1.75em;
    table-layout: fixed !important;
    width: 100%;
    box-shadow : none !important;
}
.order-box table, th, td {
    border: 0 !important;
}
.order_detail_desktop .order-box table td {
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    color: #535353;
}
.customer tbody td:first-of-type {
    padding-top: 0 !important;
}

/* Account & Order Page Mobile CSS */

@media (max-width: 790px) {
    .order_detail_desktop {
        display: inherit;
    }
    .order_detail_mobile {
        display: none;
    }
    .order_detail_desktop {
        display: none;
    }
    a.return_order_link {
        display:none;
    }
    .order_detail_mobile {
        display: block;
    }
    .order_detail_mobile .order-box, .order_detail_mobile .order-box p {
        font-size: 12px;
        line-height: 15px;
        color: #535353;
    }
    .order_detail_mobile .order-box {
        margin: 15px 0;
    }
    .order_detail_mobile .order-box h4 {
        font-size: 16px;
        line-height: 22px;
        color: #021641;
        text-transform: uppercase;
    }
    .order_detail_mobile .order-box h4 {
        font-size: 16px;
        line-height: 22px;
        color: #021641;
        text-transform: uppercase;
    }
    .order_detail_mobile .order-box p {
        margin-bottom: 0;
    }
    .order_detail_mobile .order-box, .order_detail_mobile .order-box p {
        font-size: 12px;
        line-height: 15px;
        color: #535353;
    }
    .order_detail_mobile hr {
        border: 0;
        background: #acacac;
        height: 1px;
        margin: 30px 0;
        width: 100%;
    }
    .order_detail_mobile .order-box td {
        font-size: 12px;
        line-height: 15px;
        color: #535353;
    }
    .order_detail_mobile .order-box th, td {
        padding: .4375em !important;
        border-width: 0 1px 1px 0;
    }
    .order_detail_mobile .order-box td+td {
        text-align: right;
        width: 30%;
    }
    .order_detail_table .order_items {
        margin-bottom: 10px;
    }
    .order_detail_table .order_items .order_item td.product_image {
        width: 58%;
    }
    .order_detail_table .order_items .order_item td p, .order_detail_table .order_items .order_item td a, .order_detail_table .order_items .order_item td {
        font-size: 12px;
        line-height: 15px;
        color: #535353;
    }
    .account-box .profile-address .address-icon, .account-box .my_order_box .order-icon, .account-box .profile-info .profile-icon {
        background-size: 40px auto;
        padding-left: 60px;
        min-height: 40px;
    }
    .account-box .profile-address .left-margin-80, .account-box .my_order_box .left-margin-80, .account-box .profile-info .left-margin-80, .left-margin-80 {
        margin-left: 60px;
    }
    .responsive-table thead {
        display: none;
    }
    .responsive-table tr, .responsive-table td {
        float: left;
        clear: both;
        width: 100%;
    }
    .responsive-table td:before {
        content: attr(data-label);
        float: left;
        text-align: center;
        font-size: 12px;
        padding-right: 10px;
    }
    .responsive-table tr {
        display: block;
    }
    .responsive-table th, .responsive-table td {
        display: block;
        text-align: right;
        padding: 27.5px;
        border: 0;
        margin: 0;
    }
    .responsive-table tr, .responsive-table td {
        float: left;
        clear: both;
        width: 100%;
    }
}


table.responsive-table.order_table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 0 1.75em;
    table-layout: fixed;
    width: 100%;
    font-family: Gotham;
    font-weight: 500;
}

.order_table th, .cart-page .cart table .cart__header th {
    padding: 15px 0 !important;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    font-size: 15px;
    line-height: 17px;
    font-weight: 600;
    border-bottom: 2px solid #000000 !important;
    font-family: Work Sans, HelveticaNeue, Helvetica Neue, sans-serif;
    background-color: transparent;
}
.order_table th, .order_table td {
    border: 0;
}
.order_table td {
    border-bottom: 2px dotted #000000 !important;
}
.order_table td, .cart-page .cart table .cart__row td {
    padding: 20px 0;
    vertical-align: top;
    border-bottom: 2px solid #000000;
    font-size: 16px;
    line-height: 22px ;
    color: #000;
    font-family: Gotham;
}
.order_table a.btn {
    font-size: 14px !important;
    line-height: 20px;
    padding: 0;
    border: 0;
    font-weight: 700;
    color: #000;
    text-decoration: underline !important;
}
caption, th, td {
    font-weight: 400;
    text-align: left;
}
.order_table td {
    padding: 10px 0 !important;
    font-size: 14px;
    line-height: 20px;
}
.order_table td.status {
    color: #ef2f95 !important;
    font-weight: 600;
}
table.responsive-table.order_table tbody {
    background-color: transparent !important;
}
.account-box .profile-address p {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    font-family: Gotham Book;
    font-weight: 400;
}

.account-box .profile-address a.btn.btn--small {
    font-size: 13px !important;
}
.my_address_page .section-header .btn:hover, .my_address_page .section-header .btn:focus, .account-box .profile-address .btn:hover, .account-box .profile-address .btn:focus, .empty-page-content .btn:hover, .empty-page-content .btn:focus, .cart-page .cart table .btn:hover, .cart-page .cart table .btn:focus, .cart-page .cart .cart__footer .btn:focus, .cart-page .cart .cart__footer .btn:hover .customer button:hover {
    background: #ec008c;
    color: #fff;
    cursor: pointer;
    border: 2px solid #ec008c;
    text-decoration: none;
}

.edit-address li > button {
    margin-top: 10px !important;
}
.customer.addresses.section-template--16453521473601__main-padding {
    width: 100%;
    border-bottom: 2px solid #6a6a6a;
    margin-bottom: 30px;
}
.activate button[name=decline], .addresses li>button, .addresses form button[type] {
    border: 1px solid #000;
}

/* SMTECH-965 Slick Dots not vissible issue css starts */
.slick-dots {
    bottom : 18px !important;

}
.slick.large-up-hide.medium-hide.medium-up-hide.slick-initialized.slick-slider.slick-dotted .slick-dots li:after {
    border: 0.5px solid #0000004a;
}


/* Pre-order Sticky bar design fix which is not visible in BJ */
@media only screen and (max-width: 768px) {
    .desk_sticky_bar.visible {
        top: 100% !important;
        display: block;
        margin-top: -46px;
        z-index: 2;
        position: fixed;
    }
}

@media only screen and (max-width: 768px) {
    .desk_sticky_bar {
        display: none;
        background: none;
        border: none;
        height: 0;
        padding: 0;
    }
}
@media only screen and (max-width: 768px) {
    .sticy_bar_inner {
        display: block;
        padding: 0;
        position: fixed;
    }
}
@media only screen and (max-width: 768px) {
    .sticy_bar_left, .sticy_bar_right {
        display: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .sticy_bar_inner button#sticky-add-to-cart-btn {
        bottom: 0; /* Fixes the button to the bottom of the screen */
        z-index: 9999; /* Ensures the button stays on top if other elements overlap */
    }
}
@media only screen and (max-width: 768px) {
    button#sticky-add-to-cart-btn {
        width: 100%;
        max-width: none;
    }
}
@media only screen and (max-width: 768px) {
    .sticy_bar_inner form#sticky-add-to-cart-form {
        width: 100% !important;
        height: 66px !important;
        position: fixed;
    }
}
@media only screen and (max-width: 768px) {
    .sticy_bar_inner .back-in-stock-app-container {
        width: 100% !important;
        height: 66px !important;
    }
}
.yotpo-widget-instance .yotpo-review-card .yotpo-more-media{z-index: 1 !important;}
div#fs-product-grid-container .product-container-visibility-hidden {
    contain-intrinsic-size: auto none;
}